// @flow strict
import React from "react";
import { Link } from 'gatsby'
import styles from "./Terms.module.scss";

type Props = {
  terms: string,
  url: string,
};

const Terms = ({ terms, url }: Props) => (
  <div className={styles["terms"]}>
    <Link to={url}>{terms}</Link>
  </div>
);

export default Terms;
