// @flow strict
import { useStaticQuery, graphql } from 'gatsby';

const usePinnedPosts = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query AllPinnedPostsQuery {
        allMarkdownRemark(
          limit: 5
          filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true }, pinned: { eq: true}} }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date
                category
                description
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges;
};

export default usePinnedPosts;
